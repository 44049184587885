<template>
  <v-container>

		<h1>
			<span>База знаний</span>
		</h1>
		

		
		
		<section>
			
			<div class="files-library knowledge-base">
			<router-view :container-id="0" nav-parent="/knowledge/files"></router-view>

		</div>
			
			
		</section>

  </v-container>
</template>

<script>

export default {
  name: 'KnowledgeComponent',
}
</script>
