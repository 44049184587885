<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">

        <KnowledgeComponent/>

    </transition>
  </div>
</template>

<script>

import KnowledgeComponent from '@/components/KnowledgeComponent.vue'

export default {
  name: 'Knowledge',
  components: {
    KnowledgeComponent
  }
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
</style>
